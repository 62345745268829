var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c("div", { staticClass: "animated fadeIn" }, [_vm._v(" Config ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }