<script>
import Layout from '@layouts/main'

export default {
  name: 'AdminConfig',
  page: {
    title: 'Configurações',
  },
  components: {
    Layout,
  },
}
</script>

<template>
  <Layout>
    <div class="animated fadeIn">
      Config
    </div>
  </Layout>
</template>
